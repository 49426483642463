// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlbumPageTrackCard_AlbumPageTrackCard__zYbOE {
  display: flex;
  flex-direction: row;
  /* border-bottom-style: solid; */
  border-color: rgb(41, 41, 41);
  padding-top: .2em;
  padding-bottom: .2em;
  padding-right: 5vh;
}
  
.AlbumPageTrackCard_AlbumPageTrackCard__zYbOE:hover {
  background-color: rgb(209, 189, 215);
}
  
  .AlbumPageTrackCard_info__\\+29\\+7 {
    display: flex;
    flex-direction: row;
    width: 85vw;
    padding-left: 1vw;
  }

  .AlbumPageTrackCard_trackNumber__PMPDN{
    margin-right:6vw;
  }
  .AlbumPageTrackCard_title__l8Sya {
    font-size: 17px;
    width: 40vw;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .AlbumPageTrackCard_artist__GKkUA {
    font-size: 14px;
    text-decoration: none;
    color: grey
  }
  .AlbumPageTrackCard_album__HGTtt {
    text-decoration: none;
    color: grey;
    width: 35vw;
  }

  .AlbumPageTrackCard_plays__d7T8f{ 
    display: flex;
    width: 5vw;
    text-align: right;
    align-items: center;
    font-size: 12px
  }

  .AlbumPageTrackCard_formattedTime__PQ9fW{
    width: 5vw;
    font-size: 12px
  }

  .AlbumPageTrackCard_selected__qqf1h {
    background-color: rgb(65, 65, 65)
  }
`, "",{"version":3,"sources":["webpack://./src/components/AlbumPageTrackCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gCAAgC;EAChC,6BAA6B;EAC7B,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;EAEE;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;EACA;IACE,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB;EACF;EACA;IACE,qBAAqB;IACrB,WAAW;IACX,WAAW;EACb;;EAEA;IACE,aAAa;IACb,UAAU;IACV,iBAAiB;IACjB,mBAAmB;IACnB;EACF;;EAEA;IACE,UAAU;IACV;EACF;;EAEA;IACE;EACF","sourcesContent":[".AlbumPageTrackCard {\n  display: flex;\n  flex-direction: row;\n  /* border-bottom-style: solid; */\n  border-color: rgb(41, 41, 41);\n  padding-top: .2em;\n  padding-bottom: .2em;\n  padding-right: 5vh;\n}\n  \n.AlbumPageTrackCard:hover {\n  background-color: rgb(209, 189, 215);\n}\n  \n  .info {\n    display: flex;\n    flex-direction: row;\n    width: 85vw;\n    padding-left: 1vw;\n  }\n\n  .trackNumber{\n    margin-right:6vw;\n  }\n  .title {\n    font-size: 17px;\n    width: 40vw;\n    text-align: left;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n  \n  .artist {\n    font-size: 14px;\n    text-decoration: none;\n    color: grey\n  }\n  .album {\n    text-decoration: none;\n    color: grey;\n    width: 35vw;\n  }\n\n  .plays{ \n    display: flex;\n    width: 5vw;\n    text-align: right;\n    align-items: center;\n    font-size: 12px\n  }\n\n  .formattedTime{\n    width: 5vw;\n    font-size: 12px\n  }\n\n  .selected {\n    background-color: rgb(65, 65, 65)\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AlbumPageTrackCard": `AlbumPageTrackCard_AlbumPageTrackCard__zYbOE`,
	"info": `AlbumPageTrackCard_info__+29+7`,
	"trackNumber": `AlbumPageTrackCard_trackNumber__PMPDN`,
	"title": `AlbumPageTrackCard_title__l8Sya`,
	"artist": `AlbumPageTrackCard_artist__GKkUA`,
	"album": `AlbumPageTrackCard_album__HGTtt`,
	"plays": `AlbumPageTrackCard_plays__d7T8f`,
	"formattedTime": `AlbumPageTrackCard_formattedTime__PQ9fW`,
	"selected": `AlbumPageTrackCard_selected__qqf1h`
};
export default ___CSS_LOADER_EXPORT___;
