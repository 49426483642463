import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './SoundRecordist.module.css';
import ReactPlayer from 'react-player';
import { IoCloseOutline } from "react-icons/io5";

const videos = [
  {
    id: 8,
    title: 'Lumberjack',
    pageLink: '/lumberjack',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/8ce386c5-29bd-4ab5-86c7-f372368dbce2-lumberjack%20poster.jpg',
  },
  {
    id: 1,
    title: 'Hello Goodbye',
    videoUrl: 'https://www.youtube.com/watch?v=J0IuKPfL3Ag', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/9dd569eb-3340-41c2-b092-b40c0764f532-Hello_Goodbye-smaller.jpg',
  },
  {
    id: 2,
    title: 'Buzzfeed',
    website: 'https://www.youtube.com/watch?v=5YnLBGf2bQs', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/2916a819-6f31-45eb-9519-14a050b596eb-Buzzfeed%20Presents%20poster.jpg',
  },
  {
    id: 7,
    title: 'Adidas Paris',
    videoUrl: 'https://video.wixstatic.com/video/7d2b5d_48c473509b234301a0af42a8faeeac5b/1080p/mp4/file.mp4',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/42c315df-2fef-4333-9459-7f2d36287bb0-adidas%20paris.png',
    description: 'Record vintage style drums and mix to tape'
  },
  {
    id: 3,
    title: 'John Sunshine JSLRNRT',
    videoUrl: 'https://vimeo.com/686909888',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/b0691f10-c810-458f-8e87-4ae5042dd9ef-John%20Sunshine%20JSLRNRT%20poster.png',
  },
  {
    id: 6,
    title: 'The Portland Sessions',
    website: 'https://www.theportlandsessions.com/',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/dc29cbde-ed3b-4ed6-adcf-e7ae29d5f505-The%20Portland%20Sessions%20logo.png',
  },
  {
    id: 2,
    title: 'Agnes Addington',
    website: 'https://bencleek.wixsite.com/benjamincleek/agnes-addington', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/7206a2d5-8a91-4eca-af59-83d91a757710-Agnes%20Addington.jpg',
  },
  {
    id: 5,
    title: 'Slowly Quickly',
    videoUrl: 'https://vimeo.com/11598388',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/0a323ad9-b610-4377-a693-d1ce5c707c65-Slowly%20Quickly%20%281%29.jpg',
  },
];

const VideoCard = ({ video, onClick }) => (
  <div 
    className={styles.videoCard} 
    onClick={(event) => onClick(video, event)}
  >
    <div className={styles.imageWrapper}>
      <img className={styles.videoImg} src={video.thumbnail} alt={video.title} />
      <div className={styles.infoCard}>
        <h3>{video.title}</h3>
      </div>
    </div>
  </div>
);

const SoundRecordist = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPlayerVisible, setPlayerVisible] = useState(false);
  const playerRef = useRef(null);

  const handleVideoClick = (video, event) => {
    console.log('Video Clicked:', video);

    // Check for website property and open it in a new tab
    if (video.website) {
      window.open(video.website, '_blank');
      return;
    }

    // Check for pageLink property and open it in a new tab
    if (video.pageLink) {
      window.open(video.pageLink, '_blank');
      return;
    }

    // Toggle player visibility for videoUrl
    if (selectedVideo && selectedVideo.id === video.id) {
      setSelectedVideo(null);
      setPlayerVisible(false);
    } else {
      setSelectedVideo(video);
      setPlayerVisible(true);
    }

    event.stopPropagation();
  };

  const handleCloseModal = () => {
    setPlayerVisible(false);
    setSelectedVideo(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        playerRef.current &&
        !playerRef.current.contains(event.target) &&
        event.target.className !== styles.button &&
        event.target.closest(`.${styles.modalContent}`) === null
      ) {
        handleCloseModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [playerRef]);

  return (
    <div className={styles.soundRecordist}>
      <div className={styles.videoThumbs}>
        {videos.map((video) => (
          <VideoCard key={video.id} video={video} onClick={handleVideoClick} />
        ))}
        {selectedVideo && isPlayerVisible && (
          <div className={styles.modal}>
            <div className={styles.modalContent} ref={playerRef}>
              {selectedVideo.videoUrl && (
                <ReactPlayer
                  url={selectedVideo.videoUrl}
                  width="100%"
                  height="100%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  controls
                  playing
                />
              )}
              <button onClick={handleCloseModal} className={styles.button}>
                <IoCloseOutline />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SoundRecordist;
