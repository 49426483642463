import React, { useState, useEffect, useRef } from 'react';
import styles from './Home.module.css';
import ReactPlayer from 'react-player';


const videos = [
  {
    id: 1,
    title: 'Two Funerals',
    videoUrl: 'https://vimeo.com/856621282',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/876dd415-e840-4396-a407-7a59fe7972a7-TFAAF%20-%20Two%20Funerals%20poster%20-%20smaller.jpg',
  },
  {
    id: 5,
    title: 'Spring',
    videoUrl: 'https://www.youtube.com/watch?v=732w9e0MXzA&t=11s', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/1998ce28-1e24-40c4-a990-b8e6602664d7-Spring%20-%20awarded.jpg',  
  },

];



const Home = () => {


  return (
    <div >
        {/* <div className={styles.disclaimer}>
          Please bare with my as I continue to refine my website. In my spare time I am learning how to program. I built this site and all the functions in it from scratch. It isn't perfect yet, but I made it with my own hands.
        </div> */}
       
       <div className={styles.videos}>
        recentProjects
        <div className={styles.tfaaf}>

          <ReactPlayer
            url="https://vimeo.com/856621282"
            controls
            playing
            light={true}
          />
        </div>
        <div className={styles.helloGoodbye}>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=J0IuKPfL3Ag"
            playing
            light={true}
          />
        </div>
        <div className={styles.spring}>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=732w9e0MXzA"
            playing
            light={true}
          />
        </div>
      </div>
      {/* <div className={styles.activity}>
        <div className={styles.row1}>
          <div className={styles.fakeNewsCard}>
            latest release
            <img src={"https://generative-placeholders.glitch.me/image?width=600&height=300&style=triangles&gap=30"} /> 
          </div>
          <div className={styles.fakeNewsCard}>
            Performance
            <img src={"https://generative-placeholders.glitch.me/image?width=600&height=300&style=triangles&gap=30"} /> 
          </div>
          <div className={styles.fakeNewsCard}>
            other activity
            <img src={"https://generative-placeholders.glitch.me/image?width=600&height=300&style=triangles&gap=30"} /> 
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
