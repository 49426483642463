import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, useMatch, useLocation } from 'react-router-dom';
import styles from './Film.module.css';
import FilmScores from './FilmScores';
import SoundDesign from './SoundDesign';
import SoundRecordist from './SoundRecordist';

const Film = () => {
  const match = useMatch('/film/*');
  const location = useLocation();
  const [toggleState, setToggleState] = useState(1);
  const [showTabs, setShowTabs] = useState(window.innerWidth > 768);

  const toggleTab = (index) => {
    console.log(`Toggling to tab ${index}`);
    setToggleState(index);
  };

  const handleResize = () => {
    setShowTabs(window.innerWidth > 768);
  };

  useEffect(() => {
    console.log('Toggle State changed:', toggleState);
  }, [toggleState]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const updateToggleState = () => {
      if (location.pathname === '/film' || location.pathname === '/film/') {
        setToggleState(1);
      } else if (location.pathname === '/film/sounddesign') {
        setToggleState(2);
      } else if (location.pathname === '/film/soundrecordist') {
        setToggleState(3);
      }
    };

    updateToggleState();
  }, [location]);

  return (
    <div className={styles.container}>
      {showTabs ? (
        <div className={styles['bloc-tabs']}>
          <Link
            to="."
            className={toggleState === 1 ? `${styles.tabs} ${styles['active-tabs']}` : styles.tabs}
            onClick={() => toggleTab(1)}
          >
            Film Scores
          </Link>
          <Link
            to="sounddesign"
            className={toggleState === 2 ? `${styles.tabs} ${styles['active-tabs']}` : styles.tabs}
            onClick={() => toggleTab(2)}
          >
            Sound Design
          </Link>
          <Link
            to="soundrecordist"
            className={toggleState === 3 ? `${styles.tabs} ${styles['active-tabs']}` : styles.tabs}
            onClick={() => toggleTab(3)}
          >
            Sound Recordist
          </Link>
        </div>
      ) : (
        <ul className={styles['list-tabs']}>
          <li
            className={toggleState === 1 ? `${styles.listItem} ${styles.activeItem}` : styles.listItem}
            onClick={() => toggleTab(1)}
          >
            <Link to=".">Film Scores</Link>
          </li>
          <li
            className={toggleState === 2 ? `${styles.listItem} ${styles.activeItem}` : styles.listItem}
            onClick={() => toggleTab(2)}
          >
            <Link to="sounddesign">Sound Design</Link>
          </li>
          <li
            className={toggleState === 3 ? `${styles.listItem} ${styles.activeItem}` : styles.listItem}
            onClick={() => toggleTab(3)}
          >
            <Link to="soundrecordist">Sound Recordist</Link>
          </li>
        </ul>
      )}
      <div>
        <Routes>
          <Route path="/" element={<FilmScores />} />
          <Route path="sounddesign" element={<SoundDesign />} />
          <Route path="soundrecordist" element={<SoundRecordist />} />
        </Routes>
      </div>
    </div>
  );
};

export default Film;