import React, { useState, useEffect, lazy, Suspense } from 'react';
import styles from './App.module.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sandbox from './components/Sandbox';
import Music from './components/Music';
import MoviePage from './components/MoviePage';
import Film from './components/Film';
import FilmScores from './components/FilmScores';
import SoundDesign from './components/SoundDesign';
import SoundRecordist from './components/SoundRecordist';
import Home from './components/Home';
import EditProfile from './components/DevPortfolio/profile/EditProfile';
import Lilly from './components/Lilly';
import Lumberjack from './components/Lumberjack';

const Admin = lazy(() => import('./components/Admin'));
const AlbumCreate = lazy(() => import('./components/AlbumCreate'));
const AlbumPage = lazy(() => import('./components/AlbumPage'));
const AlbumEdit = lazy(() => import('./components/AlbumEdit'));
const AlbumList = lazy(() => import('./components/AlbumList'));
const Bio = lazy(() => import('./components/Bio'));
const Commercial = lazy(() => import('./components/Commercial'));
const Collaborations = lazy(() => import('./components/Collaborations'));
const DevPortfolio = lazy(() => import('./components/DevPortfolio/DevPortfolio'));
const LuxuryAesthetic = lazy(() => import('./components/LuxuryAesthetic'));
const TrackList = lazy(() => import('./components/TrackList'));
const TrackEdit = lazy(() => import('./components/TrackEdit'));
const VideoList = lazy(() => import('./components/VideoList'));
const Zphoto = lazy(() => import('./components/Zphoto'));
const ZphotoList = lazy(() => import('./components/ZphotoList'));

//movie pages
const Alma = lazy(() => import('./components/Alma'));
const JSLRNRT = lazy(() => import('./components/JSLRNRT'));
const TFAAF = lazy(() => import('./components/TFAAF'));


function App() {
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [selectedMountpoint, setSelectedMountpoint] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const changeStation = (station) => {
    setSelectedStation(station);
  };

  const changeTrack = (track) => {
    setSelectedTrack(track);
  };

  const handleTrackSelect = (track) => {
    setSelectedItem(track);
  };

  const handleMountpointSelect = (mountpoint) => {
    setSelectedItem(mountpoint);
  };

  const handleStationSelect = (station) => {
    setSelectedItem(station);
  };

  const handleSpacebarKeyPress = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  return (
    <div className={styles.App}>
      <Router>
        <Navbar />
        <div className={styles.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route exact path="/admin" element={<Admin />} /> */}
              <Route exact path="/albumlist" element={<AlbumList />} />
              <Route exact path="/albumcreate" element={<AlbumCreate />} />
              <Route
                exact
                path="/AlbumEdit/:id"
                element={
                  <AlbumEdit
                    selectedTrack={selectedTrack}
                    setSelectedTrack={setSelectedTrack}
                    handleTrackSelect={handleTrackSelect}
                    changeTrack={changeTrack}
                  />
                }
              />
              <Route exact path="/AlbumPage/:albumid" element={<AlbumPage  />}/>
              <Route exact path="/bio" element={<Bio />} />
              <Route exact path="/commercial" element={<Commercial />} />
              <Route exact path="/collaborations" element={<Collaborations />} />
              <Route exact path="/devPortfolio" element={<DevPortfolio />} />
              <Route exact path="/EditProfile/:id" element={<EditProfile />} />
              <Route exact path="/film/*" element={<Film />} />
              <Route exact path="/music/*" element={<Music />} />
              <Route exact path="/Sandbox" element={<Sandbox />} />
              <Route exact path="/MoviePage" element={<MoviePage />} />
              <Route exact path="/Alma" element={<Alma />} />
              <Route exact path="/JSLRNRT" element={<JSLRNRT />} />
              <Route exact path="/TFAAF" element={<TFAAF />} />             
              <Route exact path="/Lumberjack" element={<Lumberjack />} />
              <Route exact path="/Lilly" element={<Lilly />} />
              <Route exact path="/LuxuryAesthetic" element={<LuxuryAesthetic  />} />           
              <Route exact path="/tracklist" element={<TrackList />} />
              <Route exact path="/TrackEdit/:id" element={<TrackEdit />} />
              <Route exact path="/videolist" element={<VideoList />} />
              <Route exact path="/zphoto" element={<Zphoto />} />
              <Route exact path="/zphotolist" element={<ZphotoList />} />
            </Routes>
          </Suspense>
        </div>
      </Router>
    </div>
  );
}

export default App;
