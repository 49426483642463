import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './SoundDesign.module.css';
import ReactPlayer from 'react-player';
import { v4 as uuidv4 } from 'uuid';
import { IoCloseOutline } from "react-icons/io5";

const videos = [
  {
    id: 2,
    title: 'Agnes Addington',
    website: 'https://bencleek.wixsite.com/benjamincleek/agnes-addington', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/7206a2d5-8a91-4eca-af59-83d91a757710-Agnes%20Addington.jpg',
  },
  {
    id: 13,
    title: 'Alma',
    pageLink: '/alma',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/8c352e07-0ee8-46b4-ab1b-6296a72d539f-Screen%20Shot%202024-08-03%20at%202.05.03%20PM.png',
  },
  {
    id: 12,
    title: 'Nocturne',
    videoUrl: 'https://vimeo.com/949317678',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/9faa68cf-ffce-49b5-bb78-ef23955e0d97-Nocturne.jpg',
  },
  {
    id: 3,
    title: 'John Sunshine JSLRNRT',
    videoUrl: 'https://vimeo.com/686909888',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/b0691f10-c810-458f-8e87-4ae5042dd9ef-John%20Sunshine%20JSLRNRT%20poster.png',
  },
  {
    id: 11,
    title: 'Sliding Scale',
    videoUrl: 'https://www.youtube.com/watch?v=-YEDn6mSKJg', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/8b168917-1d1f-49c4-b9e9-e57b937c1f24-Sliding%20Scale%20thumb.jpg',
  },
  {
    id: 1,
    title: 'Hello Goodbye',
    videoUrl: 'https://www.youtube.com/watch?v=J0IuKPfL3Ag', 
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/9dd569eb-3340-41c2-b092-b40c0764f532-Hello_Goodbye-smaller.jpg',
  },
  {
    id: 14,
    title: 'Lumberjack',
    pageLink: '/lumberjack',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/8ce386c5-29bd-4ab5-86c7-f372368dbce2-lumberjack%20poster.jpg',
  },
  {
    id: 5,
    title: 'Slowly Quickly',
    videoUrl: 'https://vimeo.com/11598388',
    thumbnail: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/0a323ad9-b610-4377-a693-d1ce5c707c65-Slowly%20Quickly%20%281%29.jpg',
  },
];

const VideoCard = ({ video, onClick }) => (
  <div 
    className={styles.videoCard} 
    onClick={(event) => onClick(video, event)}
  >
    <div className={styles.imageWrapper}>
      <img className={styles.videoImg} src={video.thumbnail} alt={video.title} />
      <div className={styles.infoCard}>
        <h3>{video.title}</h3>
      </div>
    </div>
  </div>
);

const SoundDesign = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPlayerVisible, setPlayerVisible] = useState(false);
  const playerRef = useRef(null);

  const handleVideoClick = (video, event) => {
    console.log('Video Clicked:', video);

    // Check for website property and open it in a new tab
    if (video.website) {
      window.open(video.website, '_blank');
      return;
    }

    // If the video has a pageLink, open it and prevent further processing
    if (video.pageLink) {
      window.open(video.pageLink, '_blank');
      return;
    }

    // If the video has a videoUrl and is currently selected, close the modal
    if (selectedVideo && selectedVideo.id === video.id) {
      setSelectedVideo(null);
      setPlayerVisible(false);
    } else {
      // Otherwise, select the video and show the player
      setSelectedVideo(video);
      setPlayerVisible(true);
    }

    event.stopPropagation();
  };

  const handleCloseModal = () => {
    console.log('Closing Modal');
    setPlayerVisible(false);
    setSelectedVideo(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        playerRef.current &&
        !playerRef.current.contains(event.target) &&
        !event.target.closest(`.${styles.modalContent}`)
      ) {
        handleCloseModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  console.log('Selected Video:', selectedVideo);
  console.log('Is Player Visible:', isPlayerVisible);

  return (
    <div className={styles.soundDesign}>
      <div className={styles.videoThumbs}>
        {videos.map((video) => (
          <VideoCard key={video.id} video={video} onClick={handleVideoClick} />
        ))}
        {selectedVideo && isPlayerVisible && (
          <div className={styles.modal}>
            <div className={styles.modalContent} ref={playerRef}>
              {selectedVideo.videoUrl && (
                <ReactPlayer
                  url={selectedVideo.videoUrl}
                  width="100%"
                  height="100%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  controls
                  playing
                />
              )}
              <button onClick={handleCloseModal} className={styles.button}>
                <IoCloseOutline />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SoundDesign;
