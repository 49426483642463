import React from 'react';
import styles from './MoviePage.module.css';
import ReactPlayer from 'react-player';



const MoviePage = () => {


  return (
    <div >
        {/* <div className={styles.disclaimer}>
          Please bare with my as I continue to refine my website. In my spare time I am learning how to program. I built this site and all the functions in it from scratch. It isn't perfect yet, but I made it with my own hands.
        </div> */}
       
       <div className={styles.videos}>
        Two Funerals and a Freezer
        <div className={styles.movie}>
          <ReactPlayer
            url="https://vimeo.com/856621282"
            width="100%"
            height="100%"
            controls
            playing
            light={true}
          />
        </div>
        
      </div>
      <div className={styles.activity}>
        <div className={styles.row1}>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/22c41676-059c-4c39-9d8e-35515bf9e580-funeral%20apparel%20-%20smaller.jpg"} /> 
          </div>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/ae88b52f-be7d-44de-bc41-6fccafc12f65-chapel%20long%20.jpg"} /> 
          </div>
        </div>
        <div className={styles.row1}>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/02730e3b-d341-457a-b9c5-0e350adb3639-maggie%20wait%20-%20smaller.jpg"} /> 
          </div>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/03ce49d1-d15d-4b2f-8355-b11c8aa37865-isaac%20busted%20-%20smaller.jpg"} /> 
          </div>
        </div>
        <div className={styles.row1}>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/dc974d77-e19f-4b96-86ae-b1e8c07ff927-viking%20police%20-%20smaller.jpg"} /> 
          </div>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/2cdbfa7a-aed0-43c4-8b2a-190167ef8576-viking%20funeral%20-%20smaller.jpg"} /> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoviePage;
