const isProduction = process.env.NODE_ENV === 'production';

const API_BASE_URL = isProduction
  ? 'https://website-backend-xj6q.onrender.com'
  // : 'http://localhost:5000';

  : 'https://website-backend-xj6q.onrender.com';

console.log('Environment:', process.env.NODE_ENV);
console.log('isProduction:', isProduction);
console.log('API_BASE_URL:', API_BASE_URL);

export const API_ENDPOINT = API_BASE_URL;
  