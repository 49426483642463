import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { IoIosArrowBack } from "react-icons/io";
import styles from './Lumberjack.module.css';
import ReactPlayer from 'react-player';



const Lumberjack = () => {


  return (
    <div className={styles.moviePage}>       
      <div className={styles.header}>
        <div>
          <Link to="/Film" className={styles.navigationArrow}>
            <IoIosArrowBack />
          </Link>
        </div>
        <div className={styles.title}>
          Lumberjack BTS
        </div>
        <div className={styles.credits}>
          
        </div>

      </div>
      <div className={styles.info}>
          Coming soon to the 2024 festival season
        <div>
          Houston Horror Film Festival
          <a href="https://www.houstonhorrorfilmfest.com/index.html" target="_blank" rel="noopener noreferrer">
            Visit Houston Horror Film Festival
          </a>
        </div>

        <div>
          Cabin In The Woods
          <a href="https://cabininthewoodsfilmfestival.com/" target="_blank" rel="noopener noreferrer">
            Visit Cabin In The Woods Film Festival
          </a>
        </div>
        </div>


      <div className={styles.videos}>
        {/* <div className={styles.movie}>
          <ReactPlayer
            url="https://vimeo.com/936860754/80b563f25e"
            width="100%"
            height="100%"
            controls
            playing
            light={true}
          />
        </div> */}
      </div>
      <div className={styles.activity}>
        <div className={styles.row1}>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/a96e74d9-779e-4391-827a-d1a5e4fd2908-Lumberjack%20-%20me%20-%20Photo%20Aug%2020%202023%2C%2023%2054%2014.jpg"} /> 
          </div>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/75c74cad-98e7-4834-9cfc-f736ab9b0191-Lumberjack%20-%20me%20-%20PXL_20230823_023109259.jpg"} /> 
          </div>
        </div>
        <div className={styles.row1}>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/048162a9-8401-456e-bd86-81f092b7b088-Lumberjack%20-%20me%20-Photo%20Aug%2020%202023%2C%2020%2033%2003.jpg"} /> 
          </div>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/71c0f422-d714-4095-8e6e-cef46107d4ca-Lumberjack+-+PXL_20230822_075437115.MP.jpg"} /> 
          </div>
        </div>
        <div className={styles.row1}>
          <div className={styles.stills}>
            <img src={""} /> 
          </div>
          <div className={styles.stills}>
            <img src={""} /> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lumberjack;
