import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

const Cropper = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState({
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
  });

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedImage(file);
  };

  const handleCrop = async () => {
    if (editorRef.current) {
      const dataUrl = editorRef.current.getImage().toDataURL();
      setImageURL(dataUrl);
    }
  };

  const handleChange = (type, value) => {
    setEditorState((prevEditorState) => ({
      ...prevEditorState,
      [type]: value,
    }));
  };

  return (
    <div>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag and drop an image here, or click to select a file</p>
          </div>
        )}
      </Dropzone>

      {selectedImage && (
        <>
          <AvatarEditor
            ref={editorRef}
            image={selectedImage}
            width={250}
            height={250}
            border={5}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={editorState.scale}
            rotate={editorState.rotate}
            position={editorState.position}
            onPositionChange={(position) => setEditorState({ ...editorState, position })}
            onScaleChange={(scale) => setEditorState({ ...editorState, scale })}
            onRotateChange={(rotate) => setEditorState({ ...editorState, rotate })}
          />

          <div>
            <label>
              Position X:
              <input
                type="number"
                value={editorState.position.x}
                onChange={(e) => handleChange('position', { x: parseFloat(e.target.value), y: editorState.position.y })}
              />
            </label>

            <label>
              Position Y:
              <input
                type="number"
                value={editorState.position.y}
                onChange={(e) => handleChange('position', { x: editorState.position.x, y: parseFloat(e.target.value) })}
              />
            </label>

            <label>
              Scale:
              <input
                type="number"
                value={editorState.scale}
                onChange={(e) => handleChange('scale', parseFloat(e.target.value))}
              />
            </label>

            <label>
              Rotate:
              <input
                type="number"
                value={editorState.rotate}
                onChange={(e) => handleChange('rotate', parseFloat(e.target.value))}
              />
            </label>
          </div>

          <button onClick={handleCrop}>Crop Image</button>

          {imageURL && <img src={imageURL} alt="Cropped" />}
        </>
      )}
    </div>
  );
};

export default Cropper;
