import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './ProducedBy.module.css'; 
import AlbumPage from './AlbumPage.js';
import AlbumAbout from './AlbumAbout.js';
import { API_ENDPOINT } from '../apiEndpoints.js';
import { Link } from 'react-router-dom';

const Music = () => {
  const [albums, setAlbums] = useState([]);
  const [albumid, setAlbumid] = useState(null);
  const [hoveredAlbum, setHoveredAlbum] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const artistid = 4;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/albums/artistid/${artistid}`);
        setAlbums(response.data);
      } catch (error) {
        console.error("Error fetching albums:", error);
        console.log("Full error response:", error.response);
        console.log("API_ENDPOINT:", API_ENDPOINT);
      }
    };

    fetchData();

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const handleAlbumClick = (albumid) => {
    console.log('Clicked albumid:', albumid);
    setAlbumid(albumid);
    setHoveredAlbum(null);
  };
  
  const handleAlbumHover = (albumid) => {
    setHoveredAlbum(albumid);
  };

  const handleAlbumLeave = () => {
    setHoveredAlbum(null);
  };

  
  const hardcodedAlbumIds = [ 231, 234 ];

  return (
    <div>
      <div className={styles.producedBy}>
        <div className={styles.header}>
          ....still under construction, more added soon
        </div>
        <div className={styles.albums}>
          {hardcodedAlbumIds.map(hardcodedId => {
            const album = albums.find(a => a.id === hardcodedId);

            if (!album) {
              return null;
            }
            return (

            <div
              key={album.id}
              className={styles.albumCard}
              onMouseEnter={() => handleAlbumHover(album.id)}
              onMouseLeave={handleAlbumLeave}
              onClick={() => handleAlbumClick(album.id)}
            >
              <Link to={`/AlbumPage/${album.id}`}>
                <img
                  src={album.fileurl}
                  alt={album.albumname}
                  className={`${styles.albumImg} ${hoveredAlbum === album.id ? styles.hoveredAlbum : ''}`}
                  onMouseEnter={() => handleAlbumHover(album.id)}
                  onMouseLeave={handleAlbumLeave}
                />
              </Link>
              {hoveredAlbum === album.id && (
                <div className={styles.hoverContent}>
                  <AlbumAbout albumid={album.id} />
                </div>
              )}
            </div>
          );
        })}
        </div>
        <div className={styles.spotify}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '50px' }}>
        <iframe
              style={{ borderRadius: '12px' }}
              src="https://open.spotify.com/embed/artist/32KAHfPyZwfR8CNw48TNws?utm_source=generator"
              width="100%"
              height="400"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '50px' }}>
            <iframe 
              style={{ borderRadius: '12px' }}
              src="https://open.spotify.com/embed/album/78m8UTCOjvzLFhUNgSkibv?utm_source=generator" 
              width="100%"
              height="400" 
              frameBorder="0" 
              allowFullScreen="" 
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
              loading="lazy">
            </iframe>
          </div>
        </div>
        
        <div className={styles.player}>
          {albumid && <AlbumPage albumid={albumid} />}
        </div>
      </div>
    </div>
  );
};

export default Music;
