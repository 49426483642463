import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ProfilePic from './ProfilePic';
import styles from './EditProfile.module.css'
import {Link} from 'react-router-dom'


export function EditProfile({ routeMatchParams }) {
  const [profile, setProfile] = useState({
    fileurl: '',
    username: '',
    first: '',
    last: '',
    email: '',
    password: '',
    dob: '',
    location: '',
  });

  console.log('Submitting form...');
  console.log('Profile:', profile);

  useEffect(() => {
    const url = `http://localhost:5000/profile/${routeMatchParams.id}`;

    axios
    .get(url)
    .then(response => {

      console.log('API Response:', response.data); // Log the response data for troubleshooting

      const data = response.data;
      const { fileurl, ...restData } = response.data; // Destructure fileurl from the response data
      console.log('File URL:', fileurl); // Log the fileurl for troubleshooting


      setProfile(prevProfile => ({
        ...prevProfile,
        fileurl: fileurl ? fileurl.toString() : '', // Convert fileurl to a string if it exists
        ...restData, // Set the rest of the data in the profile state
        username: data.username,
        first: data.first,
        last: data.last,
        email: data.email,
        password: data.password,
        dob: data.dob,
        location: data.location,
      }));
      console.log('File URL:', fileurl.toString()); // Add this line to log the fileurl as a string

    })
    .catch(function (error) {
      console.log(error);
      console.log('API Error:', error); // Log the error for troubleshooting

    });
}, [routeMatchParams.id]);


  const updateProfileValue = (fieldName, value) => {
    setProfile({ ...profile, [fieldName]: value });
  };

  const handleImageSubmit = fileurl => {
    setProfile(prevProfile => ({
      ...prevProfile,
      fileurl: fileurl, // Update the fileUrl in the profile state
    }));
  };
  
  

  const handleSubmit = e => {
    e.preventDefault();
  
    console.log('Submitting form...');
    console.log('Profile:', profile); // Add this line to log the profile object

  
    const url = `http://localhost:5000/profile/${routeMatchParams.id}`;
    console.log('Request URL:', url); // Add this line
  
    axios
      .put(url, profile)
      .then(res => {
        console.log('Response:', res.data); // Add this line
        console.log('Profile saved!');
        window.location = '/profilesList';
      })
      .catch(error => {
        console.log('Error:', error); // Add this line
      });
  };
  

  return (
    <div className={styles.editProfileCard}>
      <div className={styles.header}>
        Edit Profile
      </div>
      <form 
        onSubmit={handleSubmit}
        className={styles.editProfileForm}
      >
        <div className={styles.row1}>
          <ProfilePic 
            fileurl={profile.fileurl} 
            onSubmit={handleImageSubmit} 
          />
          <div className={styles.userName}> 
            <label style={{ marginRight: '10px' }}>Username:</label>
            <input  
              type="text"
              name="username"
              value={profile.username}
              onChange={updateProfileValue}
            />
          </div>
        </div>        
        <div className={styles.row}>
          <div className={styles.email}>
            <label style={{ marginRight: '3vw' }}>Email:</label>
            <input
              type="text"
              name="email"
              className="form-control"
              value={profile.email}
              onChange={updateProfileValue}
            />
          </div>
          <div className={styles.password}>
            <label style={{ marginLeft:'4vw', marginRight: '2vw' }}>Password:</label>
            <input
              type="text"
              name="password"
              className="form-control"
              value={profile.password}
              onChange={updateProfileValue}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.firstName}> 
            <label style={{ marginRight: '2vw', width: '12vw' }}>First Name:</label>
            <input
              type="text"
              name="first"
              className="form-control"
              value={profile.first}
              onChange={updateProfileValue}
            />
          </div>
          <div className={styles.lastName}> 
            <label style={{ marginLeft:'4vw', marginRight: '2vw', width: '12vw' }}>Last Name:</label>
            <input
              type="text"
              name="last"
              className="form-control"
              value={profile.last}
              onChange={updateProfileValue}
            />
          </div>  
        </div>
        <div className={styles.row}>
          <div className={styles.dob}>
            <label style={{ marginRight: '1vw', width: '10vw' }}> Date of birth:</label>
            <input
              type="text"
              name="dob"
              className="form-control"
              value={profile.dob}
              onChange={updateProfileValue}
            />
          </div>
          <div className={styles.location}>
            <label style={{  marginRight: '1vw', width: '15vw' }}> Location:</label>
            <input
              type="text"
              name="location"
              className="form-control"
              value={profile.location}
              onChange={updateProfileValue}
            />
          </div>
        </div>

        <div className={styles.buttonFooter}>
            <div className={styles.buttons}>
              <Link to="/ProfilesList" >
                <button type="submit" className="btn btn-secondary" style={{ marginRight: 5, width: 60, padding:0, fontSize: 15 }}>
                  Cancel
                </button>
              </Link>
                <button type="submit" className="btn btn-primary" style={{ marginLeft: 5, width: 55, padding:0, fontSize: 15 }}>
                  Save
                </button>
            </div>
          </div>
        </form>
      <div >
    </div>
  </div>

  );
}

export default function EditProfileWrapper(props) {
  const routeMatchParams = useParams();

  return <EditProfile {...props} routeMatchParams={routeMatchParams} />;
}

export function FormField({ name, label, values, onChange: providedOnChange }) {
  const value = values[name] || ''; 

  const onChange = event => providedOnChange(name, event.target.value);

  return (
    <div>
      <label>{label}</label>
      <input
        type="text"
        name={name}
        className="form-control"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
