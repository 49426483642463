// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .profilePic{
  display: flex;
  flex-direction: column;
  height: 10vw;
  width: 10vw;
  border-style: solid;
  align-items: center;
  justify-content: center;  
} */


.ProfilePic_dropzone__eq07S {
  /* width:10vw;
  height: 10vw; */
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-radius: 100px;
  border-color: darkgrey;
  background-color: rgb(252, 252, 252);
}


.ProfilePic_iconContainer__qT9-X {
 
}



.ProfilePic_selectedImage__d6mu2 {
  object-fit: contain;  
  overflow: hidden;
}


`, "",{"version":3,"sources":["webpack://./src/components/DevPortfolio/profile/ProfilePic.module.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG;;;AAGH;EACE;iBACe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,oCAAoC;AACtC;;;AAGA;;AAEA;;;;AAIA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["/* .profilePic{\n  display: flex;\n  flex-direction: column;\n  height: 10vw;\n  width: 10vw;\n  border-style: solid;\n  align-items: center;\n  justify-content: center;  \n} */\n\n\n.dropzone {\n  /* width:10vw;\n  height: 10vw; */\n  justify-content: center;\n  align-items: center;\n  border-style: solid;\n  border-radius: 100px;\n  border-color: darkgrey;\n  background-color: rgb(252, 252, 252);\n}\n\n\n.iconContainer {\n \n}\n\n\n\n.selectedImage {\n  object-fit: contain;  \n  overflow: hidden;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": `ProfilePic_dropzone__eq07S`,
	"iconContainer": `ProfilePic_iconContainer__qT9-X`,
	"selectedImage": `ProfilePic_selectedImage__d6mu2`
};
export default ___CSS_LOADER_EXPORT___;
