import React from 'react';
import styles from './MoviePage.module.css';
import ReactPlayer from 'react-player';



const MoviePage = () => {


  return (
    <div>       
      <div className={styles.videos}>
      Lilly
        <div className={styles.movie}>
          <ReactPlayer
            url="https://vimeo.com/907283844"
            width="100%"
            height="100%"
            controls
            playing
            light={true}
          />
        </div>
      </div>
      <div className={styles.activity}>
        <div className={styles.row1}>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/27ce749f-0cf7-4ce4-bb95-52b2971a78df-Lilly%20OG%20Poster.png"} /> 
          </div>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/5727832f-61d7-4107-bee7-24827ff8028c-Screen%20Shot%202023-12-22%20at%2011.39.45%20AM.png"} /> 
          </div>
        </div>
        <div className={styles.row1}>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/faa1e339-78a0-4c77-b8a0-63e7bdaa076e-221c2f66-8475-470d-9e38-16bffcd78e94-4b16f774-c247-411c-9e3c-d11261284a89-Screen%20Shot%202023-12-22%20at%2011.49.40%20AM%20%281%29%20%281%29.png"} /> 
          </div>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/13b64fff-7767-44f6-9a05-2c1be290482b-Lilly%20hand%20leaves.jpg"} /> 
          </div>
        </div>
        <div className={styles.row1}>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/4fc5ccbe-8530-4291-a9a5-f2078034a3c6-Screen%20Shot%202023-12-22%20at%2011.47.38%20AM.png"} /> 
          </div>
          <div className={styles.stills}>
            <img src={"https://benjamincleek.s3.us-west-2.amazonaws.com/photos/4a07fd24-57d1-4c2f-bc27-fabc183db648-Lilly%20-%20who%27s%20there.jpg"} /> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoviePage;
