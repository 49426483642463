// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.AlbumPageAlbumPhoto_selectedImage__ksG5F {
  display: flex;
  position: relative;
}

.AlbumPageAlbumPhoto_selectedImage__ksG5F img {
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/AlbumPageAlbumPhoto.module.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["\n\n.selectedImage {\n  display: flex;\n  position: relative;\n}\n\n.selectedImage img {\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedImage": `AlbumPageAlbumPhoto_selectedImage__ksG5F`
};
export default ___CSS_LOADER_EXPORT___;
