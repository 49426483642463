import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import styles from './ProfilePic.module.css';
import { BsImage, BsUpload } from 'react-icons/bs';
import { API_ENDPOINT } from '../../../apiEndpoints';

export default function ProfilePic({ onSubmit, fileurl, className }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [originalFileurl, setOriginalFileurl] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (fileurl) {
      setSelectedImage(fileurl);
      setOriginalFileurl(fileurl);
    } 
  }, [fileurl]);

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedImage(file);
    }
  };

  const handleImageSubmit = async () => {
    if (selectedImage) {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', selectedImage);

      try {
        const response = await fetch(`${API_ENDPOINT}/buskphotos/profilepic`, {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();

        console.log('Response:', response);
        console.log('Data:', data);

        if (response.ok && data.Location) {
          console.log('File uploaded successfully:', data.Location);
          onSubmit(data.Location);
        } else {
          const errorMessage = data.message || 'Unknown error occurred';
          console.log('Upload failed:', errorMessage);
        }
      } catch (error) {
        console.log('Error uploading file:', error);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div className={styles.dropzone}>
            <div className={styles.selectedImage} {...getRootProps()}>
              <input {...getInputProps()} />
              {selectedImage ? (
                <img
                  src={selectedImage instanceof File ? URL.createObjectURL(selectedImage) : selectedImage}
                  alt="Selected"
                  className={styles.circleImage}
                />
              ) : isDragActive ? (
                <div>Drop the image here</div>
              ) : (
                <div>
                  <div className={styles.iconContainer}>
                    <BsImage style={{ fontSize: 20 }} />
                    <BsUpload style={{ fontSize: 20, marginLeft: 10 }} />
                  </div>
                  <div>Click or drag an image file here</div>
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>
      {uploading ? (
        <div>Uploading...</div>
      ) : (
        <div className={styles.saveButtonContainer}>
          {selectedImage && selectedImage !== originalFileurl && (
            <>
              <button onClick={handleImageSubmit}>Save</button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
